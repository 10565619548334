const apiEndpoint = {
  userProgrammesApi: "api/programs",
  updateTasks: "api/usersprogram",
  userProgrammeDetails: "api/usersprogram",
  activityBuckets: "api/activitybuckets",
  updateApi: "api/usersprogram",
  activityUserList: "api/activityuserlist",
  activities: "api/activities",
  activityTasks: "api/activitiestasks",
  saveProgramChanges: "api/saveprogram",
  data: "data",
  callRequests: "api/callrequests",
  timeSlots: "api/timeslots",
  reschedule: "api/reschedule",
  updateCallRequest: "api/updatecallrequest",
  callRequestStatus: "api/callrequeststatus",
  programs: "api/policy-programs",
  assignprograms: "api/assignprograms",
  programAherence: "api/programadherence",
  programAdherence: "api/program-adherence",
  viewHistory: "api/viewhistory",
  callHistory: "api/callhistory",
  customtask: "api/customtask",
  encounterNotes: "api/encounter-notes",
  clientEncounterNotes: "api/client-encounter-notes",
  encounterSpeciality: "api/encounter-speciality",
  myHealthIndex: "api/hc-client-mhi",
  clientDetails: "api/clientdetails",
  unenrollProgram: "api/unenrollprogram",
  pastPrograms: "api/pastprograms",
  updateUserDetails: "api/updateuserdetails",
  dialer: "api/execute-airtel-workflow",
  discardProgramChanges: "api/discard-program-changes",
  participantsList: "api/gethclist",
  createConversation: "api/createconversation",
  ongoingEncounterNotes: "api/ongoing-consultation",
  sendFeedback: "api/feedback-requested",
  typeformResponse: "api/typeform-responses",
  clientCoachExists: "api/client-coach-exists",
  toBeScheduled: "api/to-be-scheduled",
  nonAdherence: "/api/nonadherence",
  comments: "api/comments",
  callReport: "api/callreports",
  allPrograms: "api/programs",
  authentication: "api/authenticate",
  flareUpDetails:"api/flareups-hc",
  flareUpFields:"api/flareup-fields",
  flareUpHc:"api/flareups",
  reportFlareUp:"api/flareups-v2",
  assignDietPlan:"api/client-recipes",
  verifyAssignDietPlan:"api/verify-client-recipe",
  userEncounterNotes: "api/user-encounter-notes",
  contentTask: "api/content-task",
  flareUpV2:"api/flareups-v2",
  dietList:'api/diet-templates',
  dietSearch:'api/recipes',
  allLocations: "api/locations",
};

export default apiEndpoint;
